/*
 * --------------------------------\\
 * --- Validation de formulaire ---\\
 * --------------------------------\\
 */

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve toutes les validations de formulaire, les messages d'erreur et le formatage.
// Pour que tout fonctionne, il faut les node-modules formatter et jquery-validation déjà.
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery';
import { OBSERVER } from '../main';
// -----------------------------------------------------------------------------------------------------------------


/*
 * LES FONCTIONS
 * Fonction qui gère le label des input
 */
export function fixInput() {
  // Si le focus est sur le champ, on enlève la classe vide pour que le label se déplace
  $('input').focusin(function () {
    $(this).removeClass('notEmpty');
  });

  // Si le champ est vide, on replace le label à sa position d'origine au focusout
  $('input').focusout(function () {
    $(this).val() != '' ? $(this).addClass('notEmpty') : $(this).removeClass('notEmpty');
  });
}


// Permet de supprimer le contenu d'un input
export function clearInput() {
  let i;
  const btn = document.getElementsByClassName('clearInput');
  const btnLength = btn.length;

  for (i = 0; i < btnLength; i++) {
    btn[i].addEventListener('click', (e) => {
      const input = e.currentTarget.parentElement.getElementsByTagName('input')[0];
      input.value = '';
      input.focus();
      input.parentElement.classList.remove('notEmpty');
      input.classList.remove('valid');
    });
  }
}


// Ajoute une classe en considérant si le input est plein ou vide
export function checkInput() {
  let i;
  const input = document.querySelectorAll('input');
  const inputLength = input.length;

  for (i = 0; i < inputLength; i++) {
    input[i].addEventListener('input', function (e) {
      if (e.target.value.length >= 1) {
        e.target.parentElement.classList.add('notEmpty');
      } else {
        e.target.parentElement.classList.remove('notEmpty');
      }
    });
  }
}


// Fonction permettant de modifier la hauteur d'un textarea par rapport à son contenu
export function textareaHeight() {
  const onInput = (e) => {
    e.currentTarget.style.height = '5px';
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight <= 45 ? 45 : e.currentTarget.scrollHeight}px`;
  };

  OBSERVER.add('textareaHeight', 'input', onInput, 'textarea');
  OBSERVER.on('textareaHeight');
}


// Transforme les selects
export function magicSelect() {
  $('.chosen-select').chosen({ disable_search: true });
}


/*
 * LES MESSAGES
 * Fonction contenant tous les messages
 */
export function getMessages() {
  if ($('html')[0].lang == 'en') {
    return {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf, docx, doc or rtf.',
      file: 'Please provide a adequate file.',
    };
  } else {
    return {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf, docx, doc ou rtf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.',
    };
  }
}


/*
 * LES RÈGLES __________
 * Règle du email
 */
export function validationEmail() {
  jQuery.validator.addMethod('courriel', function (value, element) {
    return this.optional(element) || (/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/).test(value);
  });
}


/*
 * LES FORMATS __________
 * Format du téléphone
 */
export function formatPhone(element) {
  $(element).mask('000 000-0000');
}

// Format du code postal
export function formatCodePostal(element) {
  $(element).mask('ABA BAB', {
    translation: {
      A: { pattern: /[A-Za-z]/ },
      B: { pattern: /[0-9]/ },
    },
  });
}

// Format de la date
export function formatDate(element) {
  $(element).mask('00/00/00');
}


/*
 * LES VALIDATIONS __________
 * Fonction exécutant la validation de chaque formulaire
 */
export function formValidation(form, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: [],
  });
  $(form).validate({
    debug: false,
    rules: rules,
    messages: messages,
    errorElement: 'div',
    errorPlacement: function (error, element) {
      const placement = $(element).data('error');
      if (element.attr('type') == 'radio') {
        error.insertBefore(element.closest('.radiobox-field').find('.errorblock'));
      } else if (element.attr('type') == 'checkbox') {
        error.insertBefore(element.closest('.checkbox-field').find('.errorblock'));
      } else if (placement) {
        $(placement).append(error);
      } else {
        error.insertBefore(element);
      }
    },
    submitHandler: function () {
      if (typeof handler != 'undefined') {
        handler();
        return false;
      }
      return true;
    },
  });
  $(submit).click(function () {
    $(form).submit();
  });
}


/*
 * LES VALIDATIONS __________
 * Validation du formulaire de demande de produit
 */
export function formProductSheet() {
  validationEmail();
  formatPhone('#formProductSheet-phone');
  const m = getMessages();
  const rules = {
    courriel: { required: true, email: true },
    entreprise: { required: true },
    nom: { required: true },
    telephone: { minlength: 12 },
  };
  const messages = {
    courriel: { required: m.required, email: m.email },
    entreprise: { required: m.required },
    nom: { required: m.required },
    telephone: { minlength: m.phone },
  };

  formValidation('#formProductSheet', '#submitProductSheet', rules, messages);
}

// Validation du formulaire de demande de prix
export function formQuoteRequest() {
  validationEmail();
  formatPhone('#formQuoteRequest-phone');
  const m = getMessages();
  const rules = {
    courriel: { required: true, email: true },
    entreprise: { required: true },
    nom: { required: true },
    telephone: { minlength: 12 },
  };
  const messages = {
    courriel: { required: m.required, email: m.email },
    entreprise: { required: m.required },
    nom: { required: m.required },
    telephone: { minlength: m.phone },
  };

  formValidation('#formQuoteRequest', '#submitQuoteRequest', rules, messages);
}

// Validation du formulaire de demande de produit
export function formCatalogRequest() {
  validationEmail();
  formatPhone('#formCatalogRequest-phone');
  const m = getMessages();
  const rules = {
    courriel: { required: true, email: true },
    entreprise: { required: true },
    nom: { required: true },
    telephone: { minlength: 12 },
  };
  const messages = {
    courriel: { required: m.required, email: m.email },
    entreprise: { required: m.required },
    nom: { required: m.required },
    telephone: { minlength: m.phone },
  };

  formValidation('#formCatalogRequest', '#submitCatalogRequest', rules, messages);
}

// Validation du formulaire de contact
export function formContact() {
  validationEmail();
  const m = getMessages();
  const rules = {
    nom: { required: true },
    prenom: { required: true },
    courriel: { required: true, email: true },
    message: { required: true },
  };
  const messages = {
    nom: { required: m.required },
    prenom: { required: m.required },
    courriel: { required: m.required, email: m.email },
    message: { required: m.required },
  };

  formValidation('#formContact', '#submitContact', rules, messages);
}

// Validation du formulaire d'emploi'
export function formJob() {
  validationEmail();
  formatPhone('#formJob-phone');
  const m = getMessages();

  let value;

  $('#formJob input[type=file]').change(function () {
    value = $(this).val().split(/(\\|\/)/g)
      .pop();
    if (value) {
      $(this).addClass('fileAdded');
      $(this).next('label').find('span')
        .text(value);
    } else {
      $(this).removeClass('fileAdded');
      $(this).next('label').find('span')
        .text('');
    }
  });

  $.validator.addMethod('filesize', function (value, element, param) {
    return this.optional(element) || (element.files[0].size <= param);
  }, 'La taille maximale du fichier doit être 5MB');

  const rules = {
    prenom: { required: true },
    nom: { required: true },
    courriel: { required: true, email: true },
    telephone: { required: true, minlength: 12 },
    cv: { required: true },
  };
  const messages = {
    prenom: { required: m.required },
    nom: { required: m.required },
    courriel: { required: m.required, email: m.email },
    telephone: { required: m.required, minlength: m.phone },
    cv: { required: m.required },
  };

  formValidation('#formJob', '#submitJob', rules, messages);
}

export function showSuccessMsg() {
  $('.step1').removeClass('active');
  $('.step2').addClass('active');
}

// AUTOCOMPLETE
export function fastResult(form) {
  // Initialisation.
  $('.resultcontainer').css('opacity', '0');
  // if ($(window).width() <= 1024)
  $('.resultcontainer').css('height', '0');

  let ajaxRequest = false; // Indique si une requete ajax pour l'autocomplete est déjà en cours
  const searchInput = $(form + ' .autocomplete'); // Pointeur sur le input de recherche

  $(searchInput).keyup(function (e) {
    if (ajaxRequest) {
      // Si une requête est déjà en cours, l'annuler pour faire place à la prochaine
      ajaxRequest.abort();
    }

    const keycode = e.keyCode ? e.keyCode : e.which;

    if (keycode != 13) {
      if ($(this).val().length >= 4) {
        ajaxRequest = $.request('rechercheAutocomplete::onFetchAutocomplete', {
          data: { q: $(this).val() },
          update: { 'rechercheAutocomplete::autocomplete': '#fastResults' },
          complete: function (data) {
            // Results loaded.
            ajaxRequest = false;
            if (data != undefined) {
              if (data.responseJSON != undefined) {
                if (data.responseJSON['rechercheAutocomplete::autocomplete'] != '') {
                  $('.resultcontainer').css('opacity', '1');
                  // if ($(window).width() <= 1024)
                  $('.resultcontainer').css('height', '100%');
                } else {
                  $('.resultcontainer').css('opacity', '0');
                  // if ($(window).width() <= 1024)
                  $('.resultcontainer').css('height', '0');
                }
              }
            }
          },
        });
      } else {
        $('.resultcontainer').css('opacity', '0');
        // if ($(window).width() <= 1024)
        $('.resultcontainer').css('height', '0');
      }
    }
  });
}

// Validation du formulaire de recherche
export function formSearch(formID, formSubmit) {
  const m = getMessages();
  const rules = {
    q: { required: true },
  };
  const messages = {
    q: { required: m.keywords },
  };

  formValidation(formID, formSubmit, rules, messages);
}

// Validation du infolettre
export function formNewsletter(formID, formSubmit) {
  validationEmail();

  const m = getMessages();
  const rules = {
    courriel: { required: true, email: true },
  };
  const messages = {
    courriel: { required: m.required, email: m.email },
  };

  formValidation(formID, formSubmit, rules, messages);
}

export function showAllResults() {
  const searchInput = $('#formSearch .autocomplete');
  $('.allResults').on('click', () => {
    if ($('#formSearch .autocomplete').val().length > 0) $('#formSearch').submit();
    else if ($('#formSearch2 .autocomplete').val().length > 0) $('#formSearch2').submit();
  });
}
