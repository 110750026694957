/*
 * -----------------------------\\
 * --- Transition avec barba ---\\
 * -----------------------------\\
 */

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// C'est ici que les transition entre les page sont gérées, on y retrouve les différentes views spécifiques à
// chaque page. Tout commence avec la fonction doOnce.
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Barba from 'barba.js';
import $ from 'jquery';
import { setView } from './views.js';
import { extractHostname } from './functions/functions.js';
// -----------------------------------------------------------------------------------------------------------------

// Récupérer l'élément cliqué
window.transitionElement = '';


// Cette fonction Gère la transition entre les page
export function initPageTransition() {
  Barba.Pjax.cacheEnabled = false;
  Barba.Pjax.Dom.wrapperId = 'transition-wrapper';
  Barba.Pjax.Dom.containerClass = 'transition-container';
  Barba.Pjax.ignoreClassLink = 'no-transition';
  setTransition();
  setView();
  Barba.Pjax.start();

  Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;

  Barba.Pjax.preventCheck = function (evt, element) {
    if (($(element).attr('href') && $(element).attr('href').indexOf('#') > -1) && $(evt.target).attr('href') && (extractHostname($(evt.target).attr('href')) == window.location.hostname)) {
      const cleanHref = $(element).attr('href').replace(/(\/#.*|\/|#.*)$/, '');
      const cleanLocation = location.href.replace(/(\/#.*|\/|#.*)$/, '');

      if (cleanHref != cleanLocation) return true;
    }
    return Barba.Pjax.originalPreventCheck(evt, element);
  };
}


// Cette fonctions exécute la transition entre les pages
function setTransition() {
  const FadeTransition = Barba.BaseTransition.extend({
    start: function () {
      Promise
        .all([this.newContainerLoading, this.fadeOut()])
        .then(this.fadeIn.bind(this));
    },
    fadeOut: function () {
      setTimeout(function () {
        $('html').removeClass('showOverlaySearch');
      }, 480);
      return $('html').addClass('transition').delay(500)
        .promise();
    },
    fadeIn: function () {
      if (!window.location.hash) $(document).scrollTop(0);
      $('html').addClass('end').removeClass('transition')
        .delay(700)
        .queue(function (next) {
          $(this).removeClass('end');
          next();
        });
      this.done();
    },
  });

  Barba.Pjax.getTransition = function () {
    return FadeTransition;
  };
}
