/*
 * ------------------------\\
 * --- Fonctions utiles ---\\
 * ------------------------\\
 */

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier on retrouve les fonctions utilitaires qu'on a souvent besoin et qui ne changent pas
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Configurer un cookie
export function setCookie(name, value, exdays = '') {
  const date = new Date();
  date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = 'expires=' + date.toUTCString();
  document.cookie = name + '=' + value + ';' + expires + ';path=/';
}


// Recevoir un cookie
export function getCookie(cookie) {
  const name = cookie + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  let i;
  for (i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return '';
}


// Retourne si l'utilisateur est sur ie
export function isIE() {
  let isIE = false;

  if (window.navigator.userAgent.indexOf('MSIE ') > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) isIE = true;

  return isIE;
}


// Retourne si l'utilisateur est sur mobile ou pas
export function isMobile() {
  let isMobile = false;

  if ((/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i).test(navigator.userAgent)) isMobile = true;

  return isMobile;
}


// Empêche une transition si la destination est la page actuelle
export function noSamePageDestination() {
  const links = document.querySelectorAll('a[href]');
  const cbk = function (e) {
    if (e.currentTarget.href === window.location.href) {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  for (let i = 0; i < links.length; i++) {
    links[i].addEventListener('click', cbk);
  }
}


// Retire tous les effets d'over sur le mobile
export function removeHoverOnMobile() {
  if ((/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i).test(navigator.userAgent)) {
    try { // prevent exception on browsers not supporting DOM styleSheets properly
      for (const si in document.styleSheets) {
        const styleSheet = document.styleSheets[si];
        if (!styleSheet.rules) continue;

        for (let ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue;
          if (styleSheet.rules[ri].selectorText.match(':hover')) styleSheet.deleteRule(ri);
        }
      }
    } catch (ex) {
      console.error(ex);
    }
  }
}


// Copier du texte
export function copyTextToClipboard(text) {
  const pos = $(document).scrollTop();

  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.log(err);
  }

  document.body.removeChild(textArea);
  $(document).scrollTop(pos);
}
