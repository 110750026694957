/*
 * ---------------------------\\
 * ---- Class Scrollfire ----\\
 * ---------------------------\\
 */

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Scrollfire trigger the value fire to the data scrollfire when the element is visible in the viewport
//
// new Scrollfire({
//   offset: NUMBER[200]    -> The offset to trigger the scrollfire
//   mobile: BOOLEAN[false] -> If it's active on mobile or not
// })
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class Scrollfire {

  // Initialization of the class
  init(object = {}) {
    object.offset === undefined ? this.offset = 200 : this.offset = object.offset;
    object.mobile === undefined ? this.mobile = false : this.mobile = object.mobile;

    document.querySelector('html').classList.contains('scroller') ? this.scrollEvent = 'smooth' : this.scrollEvent = 'scroll';

    this.isIE = window.navigator.userAgent.indexOf('MSIE ') > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./);
    this.tElements = [].slice.call(document.querySelectorAll('[data-scrollfire=""]'));
    this.tElementsLength = this.tElements.length;

    // Detect if the user is on a mobile and if the scroll is active on mobile device
    this.mobileCondition = (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i).test(navigator.userAgent);
    if (!this.mobileCondition || (this.mobileCondition && this.mobile)) {
      this.start();
    } else {
      let i;
      for (i = 0; i < this.tElementsLength; i++) this.tElements[i].setAttribute('data-scrollfire', 'fire');
    }
  }


  // Start the events
  start() {
    this.scrollfire();
    this.boundScrollfire = (e) => this.scrollfire(e);
    addEventListener(this.scrollEvent, this.boundScrollfire, false);
    addEventListener('DOMContentLoaded', this.boundScrollfire, false);
    addEventListener('load', this.boundScrollfire, false);
    addEventListener('resize', this.boundScrollfire, false);
  }


  // Update the array of scrollfire and the events
  update() {
    this.destroy();

    this.tElements = [].slice.call(document.querySelectorAll('[data-scrollfire=""]'));
    this.tElementsLength = this.tElements.length;

    this.boundScrollfire = (e) => this.scrollfire(e);
    addEventListener(this.scrollEvent, this.boundScrollfire, false);
    addEventListener('DOMContentLoaded', this.boundScrollfire, false);
    addEventListener('load', this.boundScrollfire, false);
    addEventListener('resize', this.boundScrollfire, false);
  }


  // Destroy the event and empty the array
  destroy() {
    removeEventListener(this.scrollEvent, this.boundScrollfire, false);
    removeEventListener('DOMContentLoaded', this.boundScrollfire, false);
    removeEventListener('load', this.boundScrollfire, false);
    removeEventListener('resize', this.boundScrollfire, false);
    this.tElements = [];
  }


  // Add fire to scrollfire if it's visible to the viewport or if the element has the data-now
  scrollfire() {
    let i, done, visible;

    for (i = 0; i < this.tElementsLength; i++) {
      visible = this.isElementInViewport(this.tElements[i]);

      if (this.tElements[i].hasAttribute('data-now')) {
        this.tElements[i].setAttribute('data-scrollfire', 'fire');
        done = true;
      }

      if (visible && !done) this.tElements[i].setAttribute('data-scrollfire', 'fire');

      done = false;
    }
  }


  // Return if the element is in the viewport
  isElementInViewport(e) {
    const rect = e.getBoundingClientRect();
    const offset = e.getAttribute('data-offset') ? e.dataset.offset : this.offset;

    if (this.mobileCondition) this.offset = 0;

    return (
      rect.bottom >= 0 && rect.right >= 0 && rect.top <= (window.innerHeight - offset || document.documentElement.clientHeight - offset) && rect.left <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

}
