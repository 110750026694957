/*
 * ------------------------\\
 * ---- Class Observer ----\\
 * ------------------------\\
 */

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Observer manage all event listeners
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class Observer {

  constructor() {
    this.object = {};
  }


  // Turn on a specific event listener
  on(name) {
    let i; let j; let id; let object; let objectLength; const
      objSize = this.getSize(this.object);

    for (i = 0; i < objSize; i++) {
      id = 'event' + i;
      if (this.object[id].name === name) {
        if (!this.object[id].active) {
          this.object[id].active = true;
          if (this.object[id].target !== '') {
            object = document.querySelectorAll(this.object[id].target);
            objectLength = object.length;
            for (j = 0; j < objectLength; j++) object[j].addEventListener(this.object[id].event, this.object[id].myFunction);
          } else {
            addEventListener(this.object[id].event, this.object[id].myFunction);
          }
        }
      }
    }
  }


  // Turn off a specific event listener
  off(name) {
    let i; let id; let object; let objectLength; const
      objSize = this.getSize(this.object);

    for (i = 0; i <= objSize; i++) {
      id = 'event' + i;
      if (this.object[id].name === name) {
        if (this.object[id].active) {
          this.object[id].active = false;
          if (this.object[id].target !== '') {
            object = document.querySelectorAll(this.object[id].target);
            objectLength = object.length;
            for (i = 0; i < objectLength; i++) object[i].addEventListener(this.object[id].event, this.object[id].myFunction);
          } else {
            addEventListener(this.object[id].event, this.object[id].myFunction);
          }
        }
      }
    }
  }


  // Turn on all event listeners
  allOn() {
    let i, id, object, objectLength;
    const objSize = this.getSize(this.object);

    for (i = 0; i < objSize; i++) {
      id = 'event' + i;
      if (!this.object[id].active) {
        this.object[id].active = true;
        if (this.object[id].target !== '') {
          object = document.querySelectorAll(this.object[id].target);
          objectLength = object.length;
          for (i = 0; i < objectLength; i++) object[i].addEventListener(this.object[id].event, this.object[id].myFunction);
        } else {
          addEventListener(this.object[id].event, this.object[id].myFunction);
        }
      }
    }
  }


  // Turn off all event listeners
  allOff() {
    let i, id, object, objectLength;
    const objSize = this.getSize(this.object);

    for (i = 0; i < objSize; i++) {
      id = 'event' + i;
      if (this.object[id].active) {
        this.object[id].active = false;
        if (this.object[id].target !== '') {
          object = document.querySelectorAll(this.object[id].target);
          objectLength = object.length;
          for (i = 0; i < objectLength; i++) object[i].removeEventListener(this.object[id].event, this.object[id].myFunction);
        } else {
          removeEventListener(this.object[id].event, this.object[id].myFunction);
        }
      }
    }
  }


  // Add an instance of an event in the object
  add(name, event, myFunction, target) {
    const id = 'event' + this.getSize(this.object);
    target === undefined ? target = '' : target;
    this.object[id] = {};
    this.object[id].name = name;
    this.object[id].active = false;
    this.object[id].target = target;
    this.object[id].event = event;
    this.object[id].myFunction = myFunction;
  }


  // Remove an instance of an event in the object
  remove(name) {
    let i; let id; const
      objSize = this.getSize(this.object);
    for (i = 0; i <= objSize; i++) {
      id = 'event' + i;
      if (this.object[id].name === name) break;
    }

    delete this.object[id];
  }


  // Remove all instances of all events in the object
  removeAll() {
    this.object = {};
  }


  // Get the events object
  getEvents() {
    console.log(this.object);
  }


  // Get the size of an object
  getSize(o) {
    let s = 0; let
      key;
    for (key in o) {
      if (o.hasOwnProperty(key)) s++;
    }
    return s;
  }

}
