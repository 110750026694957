/*
 * ----------------------------\\
 * ---- TimeoutEvent class ----\\
 * ----------------------------\\
 */

export class TimeoutEvent {

  constructor(instance) {
    if (instance !== undefined) this.instance = instance;

    this.init();
  }


  init() {
    let i;
    const doc = document;
    const delay = this.instance.overlay.event.opening.delay !== undefined ? this.instance.overlay.event.opening.delay : this.instance.overlay.event.delay;

    if (this.instance.overlay.event.cookie == '') {
      setTimeout(() => {
        if (!doc.querySelector('html').classList.contains(this.instance.overlay.structure.genericClass)) this.instance.open();
      }, delay);
    } else if (this.getCookie(this.instance.overlay.event.cookie) == '') {
      setTimeout(() => {
        if (!doc.querySelector('html').classList.contains(this.instance.overlay.structure.genericClass)) {
          this.instance.open();
          this.setCookie(this.instance.overlay.event.cookie, 'true', 7);
        }
      }, delay);
    }

    this.closeButtons = doc.querySelectorAll(this.instance.overlay.structure.buttons.close);
    const closeButtonsLength = this.closeButtons.length;
    for (i = 0; i < closeButtonsLength; i++) {
      this.boundClose = (e) => this.instance.close(e);
      this.closeButtons[i].addEventListener('click', this.boundClose, false);
    }
  }


  destroy() {
    let i;

    const closeButtonsLength = this.closeButtons.length;
    for (i = 0; i < closeButtonsLength; i++) this.closeButtons[i].removeEventListener('click', this.boundClose, false);
  }


  getCookie(cname) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }


  setCookie(name, value, exdays = '') {
    const date = new Date();
    date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + date.toUTCString();
    document.cookie = name + '=' + value + ';' + expires + ';path=/';
  }

}
