/*
 * ------------------------------\\
 * ---- MouseoverEvent class ----\\
 * ------------------------------\\
 */

export class MouseoverEvent {

  constructor(instance) {
    if (instance !== undefined) this.instance = instance;

    this.init();
  }


  init() {
    let i;
    const doc = document;

    this.openButtons = doc.querySelectorAll(this.instance.overlay.structure.buttons.toggle + ',' + this.instance.overlay.structure.buttons.open);
    const openButtonsLength = this.openButtons.length;
    for (i = 0; i < openButtonsLength; i++) {
      this.boundOpen = (e) => this.instance.open(e);
      this.openButtons[i].addEventListener('mouseover', this.boundOpen, false);
    }

    this.closeButtons = doc.querySelectorAll(this.instance.overlay.structure.buttons.close);
    const closeButtonsLength = this.closeButtons.length;
    for (i = 0; i < closeButtonsLength; i++) {
      this.boundClose = (e) => this.instance.close(e);
      this.closeButtons[i].addEventListener('mouseover', this.boundClose, false);
    }
  }


  destroy() {
    let i;

    const openButtonsLength = this.openButtons.length;
    for (i = 0; i < openButtonsLength; i++) this.openButtons[i].removeEventListener('mouseover', this.boundOpen, false);

    const closeButtonsLength = this.closeButtons.length;
    for (i = 0; i < closeButtonsLength; i++) this.closeButtons[i].removeEventListener('mouseover', this.boundClose, false);
  }

}
