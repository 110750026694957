// -----------------------\\
// --- Carousel Slicks ---\\
// -----------------------\\
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Toutes les fonctions utilisant les carousel de slick se retrouve ici. Ces fonction ont besoin du
// node-module slick carousel pour fonctionner (et le css qui vient avec).
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery';
// -----------------------------------------------------------------------------------------------------------------

// Création du slick de la bannière
export function slickBanner() {
  $('#slickBanner').slick({
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    draggable: true,
    speed: 800,
    autoplaySpeed: 4000,
    fade: true,
  });
}


// Création du slick de la bannière d'un produit
export function slickImages() {
  $('#slickImages').slick({
    arrows: false,
    dots: true,
    appendDots: '.slickDots',
    infinite: true,
    autoplay: true,
    draggable: true,
    speed: 800,
    autoplaySpeed: 4000,
    fade: true,
  });
}


// Création du slick de la bannière
export function slickSectors() {
  $('#slickSectors').slick({
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    draggable: true,
    speed: 500,
    autoplaySpeed: 4000,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: { slidesToShow: 2 },
      }, {
        breakpoint: 550,
        settings: { slidesToShow: 1 },
      },
    ],
  });

  $('#slickSectorsNext').on('click', () => {
    $('#slickSectors').slick('next');
  });

  $('#slickSectorsPrev').on('click', () => {
    $('#slickSectors').slick('prev');
  });
}


// Création du slick de la bannière
export function slickProductsSector() {
  $('#slickProductsSector').slick({
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    draggable: true,
    speed: 500,
    autoplaySpeed: 4000,
    slidesToShow: 3,
    lazyLoad: 'ondemand',
    responsive: [
      {
        breakpoint: 768,
        settings: { slidesToShow: 2 },
      }, {
        breakpoint: 550,
        settings: { slidesToShow: 1 },
      },
    ],
  });

  $('#slickProductsPrev').on('click', () => {
    $('#slickProductsSector').slick('prev');
  });
  $('#slickProductsNext').on('click', () => {
    $('#slickProductsSector').slick('next');
  });
}


// Création du slick des produits similaires
export function slickProducts() {
  $('#slickSimilar').slick({
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    draggable: true,
    speed: 500,
    autoplaySpeed: 4000,
    slidesToShow: 3,
    lazyLoad: 'ondemand',
    responsive: [
      {
        breakpoint: 768,
        settings: { slidesToShow: 2 },
      }, {
        breakpoint: 550,
        settings: { slidesToShow: 1 },
      },
    ],
  });

  $('.slickSimilarWrapper .next').on('click', () => {
    $('#slickSimilar').slick('next');
  });
  $('.slickSimilarWrapper .prev').on('click', () => {
    $('#slickSimilar').slick('prev');
  });
}


// Création du slick d'un projet
export function slickProject() {
  $('#slickImage').slick({
    arrows: false,
    dots: false,
    draggable: true,
    speed: 1000,
    infinite: false,
  });

  const total = $('.section_imageSlider .slick-slide:not(.slick-cloned)').length;
  $('.section_imageSlider .slickNav .total').html(total);

  $('#slickImage').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    const slidePosition = nextSlide + 1;
    $('.section_imageSlider .slickNav .current').html(slidePosition);
  });
}


// Création du slick des trois images d'un projet
export function slickThreeImages() {
  if ($(window).width() <= 600) {
    addSlick();
  }

  $(window).resize(() => {
    if ($(window).width() <= 600 && !$('#slickThreeImages').hasClass('slick-initialized')) {
      addSlick();
    } else if ($(window).width() >= 600 && $('#slickThreeImages').hasClass('slick-initialized')) {
      $('#slickThreeImages').slick('unslick');
    }
  });

  function addSlick() {
    $('#slickThreeImages').slick({
      arrows: false,
      dots: false,
      infinite: true,
      autoplay: true,
      draggable: true,
      speed: 500,
      autoplaySpeed: 4000,
      slidesToShow: 1,
    });
  }
}


// Fonction permettant de détruire un carousel Slick
export function destroySlick(carousel, delai) {
  // Paramètres optionnels
  delai = (typeof delai === 'undefined') ? 495 : delai;

  // Un délai pour que le slick ne se détruise pas avant que la transition de page ne soit apparu
  setTimeout(function () {
    $(carousel).slick('unslick');
  }, delai);
}
