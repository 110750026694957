/*
 * --------------------------\\
 * ---- ClickEvent Class ----\\
 * --------------------------\\
 */

export class ClickEvent {

  constructor(instance) {
    if (instance !== undefined) this.instance = instance;

    this.init();
  }


  init() {
    let i;
    const doc = document;

    this.switchButtons = doc.querySelectorAll(this.instance.overlay.structure.buttons.switch);
    const switchButtonsLength = this.switchButtons.length;
    for (i = 0; i < switchButtonsLength; i++) {
      this.boundSwitch = (e) => this.instance.switch(e);
      this.switchButtons[i].addEventListener('click', this.boundSwitch, false);
    }

    this.toogleButtons = doc.querySelectorAll(this.instance.overlay.structure.buttons.toggle);
    const toogleButtonsLength = this.toogleButtons.length;
    for (i = 0; i < toogleButtonsLength; i++) {
      this.boundToogle = (e) => this.instance.toggle(e);
      this.toogleButtons[i].addEventListener('click', this.boundToogle, false);
    }

    this.openButtons = doc.querySelectorAll(this.instance.overlay.structure.buttons.open);
    const openButtonsLength = this.openButtons.length;
    for (i = 0; i < openButtonsLength; i++) {
      this.boundOpen = (e) => this.instance.open(e);
      this.openButtons[i].addEventListener('click', this.boundOpen, false);
    }

    this.closeButtons = doc.querySelectorAll(this.instance.overlay.structure.buttons.close);
    const closeButtonsLength = this.closeButtons.length;
    for (i = 0; i < closeButtonsLength; i++) {
      this.boundClose = (e) => this.instance.close(e);
      this.closeButtons[i].addEventListener('click', this.boundClose, false);
    }
  }


  destroy() {
    let i;

    const switchButtonsLength = this.switchButtons.length;
    for (i = 0; i < switchButtonsLength; i++) this.switchButtons[i].removeEventListener('click', this.switchToogle, false);

    const toogleButtonsLength = this.toogleButtons.length;
    for (i = 0; i < toogleButtonsLength; i++) this.toogleButtons[i].removeEventListener('click', this.boundToogle, false);

    const openButtonsLength = this.openButtons.length;
    for (i = 0; i < openButtonsLength; i++) this.openButtons[i].removeEventListener('click', this.boundOpen, false);

    const closeButtonsLength = this.closeButtons.length;
    for (i = 0; i < closeButtonsLength; i++) this.closeButtons[i].removeEventListener('click', this.boundClose, false);
  }

}
