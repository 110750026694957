/*
 * ---------------------------\\
 * --- Fonctions générales ---\\
 * ---------------------------\\
 */

/*
 *Dans ce fichier on retrouve les fonctions générales qu'on ne peut pas vraiment catégoriser
 */

// Importation ----------------------------------------------------------------------------------------------------
import { OBSERVER, SCROLLER } from './../main.js';
import $ from 'jquery';
// -----------------------------------------------------------------------------------------------------------------

// Fonction permettant de défiller d'un block à un autre à l'aide d'un clique
export function scrollToBlock(object) {
  object.destination === undefined ? object.destination = 0 : object.destination;
  object.duration === undefined ? object.duration = 600 : object.duration;
  object.offset === undefined ? object.offset = 0 : object.offset;

  OBSERVER.add('scrollToBlock', 'click', onClick, object.buttons);
  OBSERVER.on('scrollToBlock');

  function onClick() {
    let destination;
    destination = object.destination === 0 ? object.destination : window.scroller.getElementOffset(document.querySelector(object.destination)).top;
    if ($(window).width() <= 1024) object.offset = 0;
    $('html, body').animate({ scrollTop: destination + object.offset }, object.duration, 'easeInOutExpo');
  }
}


// Fonction initialisant les tiroirs
export function drawers() {
  $('.drawers > li .toggle').on('click', function () {
    const ouvert = $(this).parent().hasClass('open');
    $('.drawers li.open .drawer').animate({ height: 'hide' }, { duration: 400, specialEasing: { height: 'easeOutExpo' } });
    $('.drawers li.open').removeClass('open');
    if (!ouvert) {
      const chosen = this;
      setTimeout(function () {
        $(chosen).parent().addClass('open');
      }, 0);
      $('.drawer', $(this).parent()).stop(true, false).animate({ height: 'show' }, { duration: 400, specialEasing: { height: 'easeOutExpo' } });
    }
    return false;
  });
}


// Fonction gérant la flèche en position fixed
export function fixedArrow(destroy) {
  const arrow = document.getElementById('fixedArrow');

  OBSERVER.add('fixedArrow', SCROLLER.getScrollEvent(), onScroll);
  OBSERVER.on('fixedArrow');

  function onScroll() {
    if (SCROLLER.getPageOffset() > 300) arrow.classList.add('active');
    else arrow.classList.remove('active');
  }
}


// Permet de slider les catégories lorsqu'ils sont à l'horizontale
export function dragAndSlide() {
  const slider = document.querySelector('.dragAndSlide');
  let startX; let scrollLeft; let
    isDown = false;

  function mouseDown(e) {
    isDown = true;
    slider.classList.add('active');
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  }

  function mouseleave() {
    isDown = false;
    slider.classList.remove('active');
  }

  function mouseup() {
    isDown = false;
    slider.classList.remove('active');
  }

  function mousemove(e) {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 2;
    slider.scrollLeft = scrollLeft - walk;
  }

  OBSERVER.add('dragAndSlide', 'mousedown', mouseDown, '.dragAndSlide');
  OBSERVER.add('dragAndSlide', 'mouseleave', mouseleave, '.dragAndSlide');
  OBSERVER.add('dragAndSlide', 'mouseup', mouseup, '.dragAndSlide');
  OBSERVER.add('dragAndSlide', 'mousemove', mousemove, '.dragAndSlide');
  OBSERVER.on('dragAndSlide');
}


export function addVideo() {

  /*
   * if(!isMobile()) {
   *   $('.videoWrapper').append('<video muted playsinline loop="loop" align="left" autoplay="autoplay"><source src="'+ $(location).attr('protocol') + '//' + $(location).attr('hostname') +'/themes/blanko/assets/videos/video.mp4" type="video/mp4"></video>')
   * }
   */
}

// Permet de défiler à une ancre
function hashScrolling(destination) {
  $('html, body').animate({ scrollTop: destination != '' ? $(destination).offset().top - 10 : 0 }, 600, 'easeInOutExpo');
}

// Permet de gérer un tiroir avec un défilement
function hashDrawer(element) {
  $('.drawers li.open .drawer').animate({ height: 'hide' }, 400);
  $('.drawers li.open').removeClass('open');
  if (!$(element).hasClass('open')) {
    setTimeout(function () {
      $(element).addClass('open');
    }, 0);
    $('.drawer', $(element)).stop(true, false).animate({ height: 'show' }, 400, function () {
      hashScrolling(element);
    });
    // Enleve l'ancre de l'url une fois que la rubrique est ouverte
    history.replaceState(null, null, ' ');
  }
  return false;
}

export function manageRubriqueHash() {
  // Pour ouvrir une rubrique dans la même page
  window.onhashchange = function () {
    const id = location.hash.split('#').pop();
    const hash = '#rubrique' + id;
    if ($('li' + hash).length) {
      hashDrawer(hash);
    }
  };

  // Pour ouvrir une rubrique dans une autre page
  $('document').ready(function () {
    if (location.hash.length) {
      const id = location.hash.split('#').pop();
      const hash = '#rubrique' + id;
      if ($('li' + hash).length) {
        hashDrawer(hash);
      }
    }
  });
}

export function extractHostname(url) {
  let hostname;
  // find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  // find & remove port number
  hostname = hostname.split(':')[0];
  // find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname;
}
