// Le package à appliquer pour les zones dynamiques
export function dynamicPackage() {
  wrapYtVideo();
  adjustButtonsMargins();
  autoTargetBlank();
  addSpanToButtons();
  addSvgToLinks();
  manageImageLinks();
}


// Englobe les iframe de vidéos youtube pour gérer le ratio
function wrapYtVideo() {
  let i;
  let youtubeVideosOuterHtml;
  let newYoutubeVideosOuterHtml;
  const youtubeVideos = document.querySelectorAll('.dynamic iframe[src*="youtube.com"]');
  const youtubeVideosLength = youtubeVideos.length;

  for (i = 0; i < youtubeVideosLength; i++) {
    youtubeVideosOuterHtml = youtubeVideos[i].outerHTML;
    newYoutubeVideosOuterHtml = `<div class='videoWrapper'><div class='ytVideo'>${youtubeVideosOuterHtml}</div></div>`;
    youtubeVideos[i].outerHTML = newYoutubeVideosOuterHtml;
  }
}


// Ajouter des marges pour les <p> qui contiennent des boutons
function adjustButtonsMargins() {
  let i;
  const buttons = document.querySelectorAll('.dynamic p > a.primary-button, .dynamic p > a.secondary-button');
  const buttonsLength = buttons.length;

  for (i = 0; i < buttonsLength; i++) buttons[i].parentNode.classList.add('buttons');

  const buttonsParagraph = document.querySelectorAll('.dynamic p.buttons');
  const buttonsParagraphLength = buttonsParagraph.length;

  for (i = 0; i < buttonsParagraphLength; i++) {
    if (buttonsParagraph[i].previousElementSibling !== null) if (!buttonsParagraph[i].previousElementSibling.classList.contains('buttons')) buttonsParagraph[i].classList.add('buttons--first');
    if (buttonsParagraph[i].nextElementSibling !== null) if (!buttonsParagraph[i].nextElementSibling.classList.contains('buttons')) buttonsParagraph[i].classList.add('buttons--last');
  }
}


// Ajouter un <span> dans les boutons pour l'animation
function addSpanToButtons() {
  let i;
  let buttonsInnerHtml;
  let newButtonsInnerHtml;
  const buttons = document.querySelectorAll('.dynamic .primary-button, .dynamic .secondary-button');
  const buttonsLength = buttons.length;

  for (i = 0; i < buttonsLength; i++) {
    buttonsInnerHtml = buttons[i].innerHTML;
    newButtonsInnerHtml = `<span>${buttonsInnerHtml}</span>`;
    buttons[i].innerHTML = newButtonsInnerHtml;
  }
}


// Ajouter les icones svg pour les boutons et les liens textes
function addSvgToLinks() {
  const themePath = window.config.theme_path;
  const linksExternal = document.querySelectorAll('.dynamic a[target=_blank]:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"])');

  linksExternal.forEach(function (link) {
    if (!link.querySelector('img')) {
      const svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      const useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use');
      useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `/themes/${themePath}/assets/images/content/icons/symbols.svg#ico-external`);
      svgElem.appendChild(useElem);
      link.appendChild(svgElem);
    }
  });

  const linksDocuments = document.querySelectorAll('.dynamic a[href$=".pdf"], .dynamic a[href$=".doc"], .dynamic a[href$=".zip"]');
  linksDocuments.forEach(function (link) {
    if (!link.querySelector('img')) {
      const svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      const useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use');
      useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `/themes/${themePath}/assets/images/content/icons/symbols.svg#ico-download2`);
      svgElem.appendChild(useElem);
      link.appendChild(svgElem);
      link.setAttribute('target', '_blank');
    }
  });
}


// Ajouter les icones svg pour les boutons et les liens textes
function manageImageLinks() {
  const links = document.querySelectorAll('.dynamic a');
  links.forEach(function (link) {
    if (link.querySelector('img')) {
      link.classList.add('link-image');
    }
  });
}

// Mettre des target _blank automatiquement sur les liens externes
function autoTargetBlank() {
  document.querySelectorAll('.dynamic a').forEach((link) => {
    link.hostname !== location.hostname && link.setAttribute('target', '_blank');
  });
}
