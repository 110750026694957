/*
 * ---------------------\\
 * ---- Debug class ----\\
 * ---------------------\\
 */

export class Debug {

  constructor(instance) {
    this.instance = instance.overlay;
    this.instance.hasError = false;
    this.error = '';

    this.init();
  }


  // Check for errors
  init() {
    if (this.instance.name === undefined) {
      this.getMessage('missingName');
    } else if (this.instance.event.name !== 'timeout' && this.instance.structure.buttons.toggle === undefined && this.instance.structure.buttons.open === undefined) {
      this.getMessage('missingOpen');
    } else if (this.instance.structure.buttons.close === undefined) {
      this.getMessage('missingClose');
    }

    if (this.error !== '') this.instance.hasError = true;
  }


  // Construct the error message
  getMessage(error) {
    const symbol = '!!!!!!!!!!!!!!!!';
    const obj = {
      missingName: 'You must provide a name to your overlay',
      missingOpen: 'You must provide at least one open or toggle Button',
      missingClose: 'You must provide at least one open or toggle Button',
    };

    if (this.instance.name !== undefined) {
      this.error = symbol + '\n!!!OVERLAY.JS!!! -> ' + 'The overlay ' + this.instance.name + ': ' + obj[error] + '\n' + symbol;
    } else {
      this.error = symbol + '\n!!!OVERLAY.JS!!! -> ' + obj[error] + '\n' + symbol;
    }
  }


  // Return if there is an error
  hasError() {
    return this.instance.hasError;
  }


  // Return the error
  getError() {
    return this.error;
  }

}
