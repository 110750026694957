/*
 * -----------------\\
 * --- Les views ---\\
 * -----------------\\
 */

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ce fichier contient une seule fonction qui celle-ci contient toutes les views du site. Une views équivaux à
// une page web (homeView = la page d'accueil). On appelle seulement les classes dans global dans les vues, on
// n'appelle pas d'autres fonctions ou déclarer quoi que ce soit dans les vues afin de garder le tout clair.
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Barba from 'barba.js';

import {
  View, Home, Products, ProductDetail, Search, News, NewsDetail, About, Expertise, Sectors, SectorDetail, Jobs,
  JobDetail, Contact,
} from './global.js';
// -----------------------------------------------------------------------------------------------------------------


export function setView() {
  // Page standart __________
  Barba.BaseView.extend({
    namespace: 'sdtView',
    onEnter: function () { },
    onEnterCompleted: function () {
      View.init();
    },
    onLeave: function () {
      View.leave();
    },
    onLeaveCompleted: function () { },
  }).init();


  // Page d'accueil __________
  Barba.BaseView.extend({
    namespace: 'homeView',
    onEnter: function () {},
    onEnterCompleted: function () {
      Home.initHome();
    },
    onLeave: function () {
      Home.leaveHome();
    },
    onLeaveCompleted: function () {},
  }).init();


  // Page des produits __________
  Barba.BaseView.extend({
    namespace: 'productsView',
    onEnter: function () {},
    onEnterCompleted: function () {
      Products.initProducts();
    },
    onLeave: function () {
      Products.leaveProducts();
    },
    onLeaveCompleted: function () {},
  }).init();


  // Page d'un produit __________
  Barba.BaseView.extend({
    namespace: 'productDetailView',
    onEnter: function () {},
    onEnterCompleted: function () {
      ProductDetail.initProductDetail();
    },
    onLeave: function () {
      ProductDetail.leaveProductDetail();
    },
    onLeaveCompleted: function () {},
  }).init();


  // Page de recherche __________
  Barba.BaseView.extend({
    namespace: 'searchView',
    onEnter: function () {},
    onEnterCompleted: function () {
      Search.initSearch();
    },
    onLeave: function () {
      Search.leaveSearch();
    },
    onLeaveCompleted: function () {},
  }).init();


  // Page des nouvelles __________
  Barba.BaseView.extend({
    namespace: 'newsView',
    onEnter: function () {},
    onEnterCompleted: function () {
      News.initNews();
    },
    onLeave: function () {
      News.leaveNews();
    },
    onLeaveCompleted: function () {},
  }).init();


  // Page d'une nouvelle __________
  Barba.BaseView.extend({
    namespace: 'newsDetailView',
    onEnter: function () {},
    onEnterCompleted: function () {
      NewsDetail.initNewsDetail();
    },
    onLeave: function () {
      NewsDetail.leaveNewsDetail();
    },
    onLeaveCompleted: function () {},
  }).init();


  // Page à propos __________
  Barba.BaseView.extend({
    namespace: 'aboutView',
    onEnter: function () {},
    onEnterCompleted: function () {
      About.initAbout();
    },
    onLeave: function () {
      About.leaveAbout();
    },
    onLeaveCompleted: function () {},
  }).init();


  // Page d'expertise __________
  Barba.BaseView.extend({
    namespace: 'expertiseView',
    onEnter: function () {},
    onEnterCompleted: function () {
      Expertise.initExpertise();
    },
    onLeave: function () {
      Expertise.leaveExpertise();
    },
    onLeaveCompleted: function () {},
  }).init();


  // Page des secteurs d'activités __________
  Barba.BaseView.extend({
    namespace: 'sectorsView',
    onEnter: function () {},
    onEnterCompleted: function () {
      Sectors.initSectors();
    },
    onLeave: function () {
      Sectors.leaveSectors();
    },
    onLeaveCompleted: function () {},
  }).init();


  // Page d'un secteur d'activités __________
  Barba.BaseView.extend({
    namespace: 'sectorDetailView',
    onEnter: function () {},
    onEnterCompleted: function () {
      SectorDetail.initSectorDetail();
    },
    onLeave: function () {
      SectorDetail.leaveSectorDetail();
    },
    onLeaveCompleted: function () {},
  }).init();


  // Page des emplois __________
  Barba.BaseView.extend({
    namespace: 'jobsView',
    onEnter: function () {},
    onEnterCompleted: function () {
      Jobs.initJobs();
    },
    onLeave: function () {
      Jobs.leaveJobs();
    },
    onLeaveCompleted: function () {},
  }).init();


  // Page d'un emploi __________
  Barba.BaseView.extend({
    namespace: 'jobDetailView',
    onEnter: function () {},
    onEnterCompleted: function () {
      JobDetail.initJobDetail();
    },
    onLeave: function () {
      JobDetail.leaveJobDetail();
    },
    onLeaveCompleted: function () {},
  }).init();


  // Page d'un secteur d'activités __________
  Barba.BaseView.extend({
    namespace: 'contactView',
    onEnter: function () {},
    onEnterCompleted: function () {
      Contact.initContact();
    },
    onLeave: function () {
      Contact.leaveContact();
    },
    onLeaveCompleted: function () {},
  }).init();
}
