/*
 * ------------------------\\
 * ---- Scroller class ----\\
 * ------------------------\\
 */

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Create easing while scrolling. It use the native scrollbar but the content is in a container and when the user
// is scrolling, it use a translate on the container that create the easing.
//
// new Scroller({
//   element:       STRING['data-scroller']         -> The first container with fixed position
//   container:     STRING['data-scroller-content'] -> The second container with the content
//   heightData:    STRING['data-height']           -> The name of the attribute on the fake height element
//   ease:          NUMBER[0.075]                   -> The intensity of the ease
//   mobile:        BOOLEAN[false]                  -> if it's active on mobile
// })
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class Scroller {

  // Initalization of the class, create the height element, set the animation
  init(object = {}) {
    object.element === undefined ? this.element = 'data-scroller' : object.element;
    object.container === undefined ? this.container = 'data-scroller-content' : object.container;
    object.heightData === undefined ? this.heightData = 'data-height' : object.heightData;
    object.ease === undefined ? this.ease = 0.075 : object.ease;
    object.mobile === undefined ? this.mobile = false : object.mobile;

    window['scroller'] = this; // Add to window
    this.position = this.getPageOffset();

    // Detect if it's Internet explorer
    this.isIE = window.navigator.userAgent.indexOf('MSIE ') > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./);
    if (!this.isIE) {
      this.container = document.querySelector('[' + this.container + ']');
      this.target = window.scrollY || window.pageYOffset;
      this.event = new Event('smooth');
      this.RAF_id = undefined;
      this.RAF_active = false;
      this.current = 0;
      this.containerHeight;
      this.containerBounding;

      // Detect if the user is on a mobile and if the scroll is active on mobile device
      this.mobileCondition = (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i).test(navigator.userAgent);
      if (!this.mobileCondition || (this.mobileCondition && this.mobile)) this.start();
    }
  }


  // Add the events listener.
  start() {
    this.containerBounding = this.container.getBoundingClientRect();
    if (!this.isIE) {
      this.createHeightElement();
      window.scrollTo(0, this.position);
      document.querySelector('html').classList.add('scroller');
      this.boundStartAnimation = (e) => this.startAnimation(e);
      window.addEventListener('resize', this.boundStartAnimation);
      this.boundUpdateScroll = (e) => this.updateScroll(e);
      window.addEventListener('scroll', this.boundUpdateScroll);
    }
  }


  // Destroy the plugin
  destroy() {
    if (!this.isIE) {
      document.querySelector('html').classList.remove('scroller');
      window.removeEventListener('resize', this.boundSetupAnimation);
      window.removeEventListener('scroll', this.boundUpdateScroll);
      document.querySelector('[' + this.element + ']').removeChild(this.heightElement);
    }
  }


  // Create the height element that can create the scrollbar to be able to scroll
  createHeightElement() {
    this.heightElement = document.createElement('div');
    this.heightElement.setAttribute(this.heightData, '');
    document.querySelector('[' + this.element + ']').appendChild(this.heightElement);
    this.containerBounding = this.container.getBoundingClientRect();
    if (this.containerHeight != this.containerBounding.height) this.containerHeight = this.containerBounding.height;
    this.heightElement.style.height = this.containerHeight + 'px';
  }


  // Start the animation
  startAnimation() {
    if (!this.RAF_active) {
      this.RAF_active = true;
      this.RAF_id = requestAnimationFrame(this.updateAnimation.bind(this));
    }
  }


  // Update the scroll
  updateScroll() {
    this.target = window.scrollY || window.pageYOffset;
    this.startAnimation();
  }


  /*
   * Set the difference between target and current scroll position. Delta is the value for adding to the current scroll position,
   * the if prevent the animation to be endless. if delta is different of 0...
   */
  updateAnimation() {
    const diff = this.target - this.current;
    const delta = Math.abs(diff) < 0.1 ? 0 : diff * this.ease;

    dispatchEvent(this.event);

    this.containerBounding = this.container.getBoundingClientRect();

    if (this.containerHeight != this.containerBounding.height) {
      this.containerHeight = this.containerBounding.height;
      this.heightElement.style.height = this.containerHeight + 'px';
    }

    if (delta) {
      this.current += delta;
      this.current = this.current;
      this.RAF_id = requestAnimationFrame(this.updateAnimation.bind(this));
    } else {
      this.current = this.target;
      this.RAF_active = false;
      cancelAnimationFrame(this.RAF_id);
    }

    this.container.style.transform = 'translateY(' + -Math.round(this.current) + 'px)';
  }


  /** ************/
  /** * GETTER ***/
  /** ************/
  // Return the bounding container
  getContainerBounding() {
    return this.containerBounding;
  }


  // Return the offset of an element in the container (without the ease effect)
  getElementOffset(e) {
    const box = e.getBoundingClientRect();
    const body = document.body;
    const docEl = document.documentElement;

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    const top = box.top + scrollTop - clientTop;
    const left = box.left + scrollLeft - clientLeft;
    if (this.mobileCondition) return { top: top, left: left };
    else return { top: top - (this.target - Math.round(this.current)), left: left };
  }


  // Return the right scroll event
  getScrollEvent() {
    if (document.querySelector('html').classList.contains('scroller')) return 'smooth';
    else return 'scroll';
  }


  // Return the page offset
  getPageOffset() {
    if (document.querySelector('html').classList.contains('scroller')) return -this.containerBounding.top;
    else return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  }

}
