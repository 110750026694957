/*
 * ----------------------------\\
 * ---- Popup et sidepanel ----\\
 * ----------------------------\\
 */

/* global $, __sharethis__ */

/*
 * Ici on retrouve tout ce qui a attrait au popup et au sidepanel (popup de connexion, menu mobile)
 */

// Importation ----------------------------------------------------------------------------------------------------
import { Overlay } from './../classes/Overlay/Overlay.js';
import { OBSERVER } from './../main.js';
import { copyTextToClipboard } from './helper.js';
// -----------------------------------------------------------------------------------------------------------------


// Fonction gérant le menu mobile
export function overlayMenu() {
  new Overlay({
    name: 'Menu',
    structure: {
      buttons: {
        toggle: '.btnMenu',
        close: '.overlay, .closeOverlay, header .left a',
      },
    },
    event: {
      name: 'click',
      speed: 800,
    },
    options: {
      goToSelector: 'html',
    },
  });
}


// Fonction gérant l'overlay de fiche de produit
export function overlayProductSheet() {
  new Overlay({
    name: 'ProductSheet',
    structure: {
      buttons: {
        open: '.btnProductSheet',
        close: '.overlay, .closeOverlay',
      },
    },
    event: {
      name: 'click',
      speed: 600,
      closing: {
        callback: function () {
          setTimeout(() => {
            $('.step1').addClass('active');
            $('.step2').removeClass('active');
          }, 500);
        },
      },
    },
  });
}

// Fonction gérant l'overlay de demande de prix
export function overlayQuoteRequest() {
  new Overlay({
    name: 'QuoteRequest',
    structure: {
      buttons: {
        open: '.btnQuoteRequest',
        close: '.overlay, .closeOverlay',
      },
    },
    event: {
      name: 'click',
      speed: 600,
      closing: {
        callback: function () {
          setTimeout(() => {
            $('.step1').addClass('active');
            $('.step2').removeClass('active');
          }, 500);
        },
      },
    },
  });
}

// Fonction gérant l'overlay de demande de catalogue
export function overlayCatalogRequest() {
  new Overlay({
    name: 'CatalogRequest',
    structure: {
      buttons: {
        open: '.btnCatalogRequest',
        close: '.overlay, .closeOverlay',
      },
    },
    event: {
      name: 'click',
      speed: 600,
      closing: {
        callback: function () {
          setTimeout(() => {
            $('.step1').addClass('active');
            $('.step2').removeClass('active');
          }, 500);
        },
      },
    },
  });
}

export function overlaySearch() {
  // Desktop
  activateWithKeyUp('#formSearch #formSearch-search', '#overlaySearch', true);
  close('.closeOverlay, header a, #overlaySearch a, #formSearch-search1-clear', '#overlaySearch', true);

  // Mobile
  activateWithKeyUp('#formSearch2 #formSearch-search2', '.resultWrapper');
  close('.closeOverlay, header a, #overlaySearch a, #formSearch-search2-clear', '.resultWrapper');

  $('.btnSearch').on('click', () => {
    const input = document.querySelector('#formSearch-search2');

    if ($('html').hasClass('showOverlaySearch')) {
      $('html').removeClass('showOverlaySearch');
      input.value = '';
      input.parentElement.classList.remove('notEmpty');
      input.classList.remove('valid');
    } else {
      $('html').addClass('showOverlaySearch');
      input.focus();
    }
  });

  function activateWithKeyUp(input, selector, htmlClass = false) {
    $(input).keyup(function () {
      if ($(this).val().length >= 4) {
        if (htmlClass) $('html').addClass('showOverlaySearch');
      } else if (htmlClass) $('html').removeClass('showOverlaySearch');
    });
  }


  function close(close, selector, htmlClass = false) {
    $(close).on('click', () => {
      if (htmlClass) $('html').removeClass('showOverlaySearch');
      $('.resultcontainer').css('opacity', '0');
      if ($(window).width() <= 1024) $('.resultcontainer').css('height', '0');
    });
  }
}


export function overlayShare() {
  const siteName = window.config.site_name;

  new Overlay({
    name: 'share',
    structure: {
      buttons: {
        open: '[data-js="open-overlay-share"]',
        close: '[data-js="close-overlay-share"]',
      },
    },
    event: {
      name: 'click',
      speed: 600,
    },
  }).init();

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config);
  }

  let message;
  if (document.querySelector('html').getAttribute('lang') == 'fr') {
    message = 'L’adresse URL a bien été copiée sur votre presse-papiers.';
  } else {
    message = 'Adaptaid.com indicates that the URL has been copied to your clipboard.';
  }

  $('.js-copy-share').on('click', function () {
    const link = $(this).attr('data-url');
    copyTextToClipboard(link);
    copyTextToClipboard(window.location.href);
    alert(message);
    return false;
  });

  $('.js-share').on('click', function (e) {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, '#' + e.currentTarget.dataset.rubric);
    }
    $('#emailShareBtn').attr('href', 'mailto:?Subject=' + siteName + '&body=' + window.location.href);
    $('#facebookShareBtn').attr('data-url', window.location.href);
    $('#twitterShareBtn').attr('data-url', window.location.href);
    $('#linkedinShareBtn').attr('data-url', window.location.href);
    $('#pinterestShareBtn').attr('data-url', window.location.href);
  });

  $('.overlay-share__background, .overlay-share__close').on('click', function () {
    window.history.replaceState(null, null, ' ');
    $('#emailShareBtn').attr('href', 'mailto:?Subject=' + siteName + '&body=' + window.location.href);
    $('#facebookShareBtn').attr('data-url', window.location.href);
    $('#twitterShareBtn').attr('data-url', window.location.href);
    $('#linkedinShareBtn').attr('data-url', window.location.href);
    $('#pinterestShareBtn').attr('data-url', window.location.href);
  });
}

// Fonction gérant l'overlay des produits
export function overlayProducts() {
  new Overlay({
    name: 'Products',
    structure: {
      buttons: {
        toggle: '.btnProducts',
        close: '.overlay, .closeOverlay, .close-overlay-products, a[href]',
        switch: '.btnSectors',
      },
    },
    event: {
      name: 'click',
      speed: 600,
    },
  });

  // Gérer les photos qui changent au hover des secteurs

  // Copier les photos de touts les secteurs dans le cta de droite
  $('.products-menu-category').each(function () {
    if ($(this).data('image')) {
      $('.products-menu-image').append('<img class="cover hidden" data-id="' + $(this).data('id') + '" src="' + $(this).data('image') + '">');
    }
  });

  // Ajouter la classe 'hover' à la photo qui correspond au bon secteur pendant qu'on survole un secteur dans le menu
  $('.products-menu-category').hover(function () {
    $(`.products-menu-image img[data-id="${$(this).data('id')}"]`).addClass('hover');
  }, function () {
    $(`.products-menu-image img[data-id="${$(this).data('id')}"]`).removeClass('hover');
  });
}

// Fonction gérant l'overlay des secteurs
export function overlaySectors() {
  new Overlay({
    name: 'Sectors',
    structure: {
      buttons: {
        toggle: '.btnSectors',
        close: '.overlay, .closeOverlay, .close-overlay-sectors, a[href]',
        switch: '.btnProducts, .btnAbout',
      },
    },
    event: {
      name: 'click',
      speed: 600,
    },
  });

  // Affecter la bonne hauteur d'overlay
  OBSERVER.add('ResizeOverlaySectors', 'resize', onResize);
  OBSERVER.on('ResizeOverlaySectors');

  function onResize() {
    const targetHeight = $('.overlay-sectors__wrapper').outerHeight() + 'px';
    $('[data-overlay="sectors"]').css('--overlay-height', targetHeight);
  }
  onResize();
}

// Fonction gérant l'overlay des secteurs
export function overlayAbout() {
  new Overlay({
    name: 'About',
    structure: {
      buttons: {
        toggle: '.btnAbout',
        close: '.overlay, .closeOverlay, .close-overlay-about, a[href]',
        switch: '.btnProducts, .btnSectors',
      },
    },
    event: {
      name: 'click',
      speed: 600,
    },
  });

  // Affecter la bonne hauteur d'overlay
  OBSERVER.add('ResizeOverlayAbout', 'resize', onResize);
  OBSERVER.on('ResizeOverlayAbout');

  function onResize() {
    const targetHeight = $('.overlay-about__wrapper').outerHeight() + 'px';
    $('[data-overlay="about"]').css('--overlay-height', targetHeight);
  }
  onResize();
}
