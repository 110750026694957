/*
 * -------------------------\\
 * --- Classes des views ---\\
 * -------------------------\\
 */

/*
 * Dans ce fichier, on retrouve les différentes fonctions utilisées par les views dans transition.js. Ces fonction
 * vont servir à appeler les scripts spécifiques ou les plugins utilisés dans les page web. Les fonctions appelées
 * sont réparties dans différents fichiers (slicks, overlay), afin de garder le tout le plus structuré possible.
 */

// Importation ----------------------------------------------------------------------------------------------------
import { SCROLLER, PARALLAX, SCROLLFIRE } from './main.js';
import svg4everybody from 'svg4everybody/dist/svg4everybody.legacy.js';
import { removeHoverOnMobile } from './functions/helper.js';
import { fixInput, clearInput, checkInput, textareaHeight, formProductSheet, formQuoteRequest, formCatalogRequest, formJob, fastResult, formSearch, showAllResults, formContact, formNewsletter } from './functions/forms.js';
import { overlayMenu, overlaySearch, overlayProductSheet, overlayQuoteRequest, overlayCatalogRequest, overlayShare, overlayProducts, overlaySectors, overlayAbout } from './functions/overlay.js';
import { scrollToBlock, drawers, dragAndSlide, fixedArrow, manageRubriqueHash } from './functions/functions.js';
import { slickBanner, slickSectors, slickProducts, slickImages, slickProductsSector, destroySlick } from './functions/slicks.js';
import { dynamicPackage } from './functions/dynamic.js';
import Lazyload from 'vanilla-lazyload';
// -----------------------------------------------------------------------------------------------------------------


// Initialisation sur toutes les pages
export class View {

  static init() {
    SCROLLER.init();
    PARALLAX.init({ customScroll: true });
    SCROLLFIRE.init({ customScroll: true, mobile: true });
    new Lazyload();
    scrollToBlock({ buttons: '#fixedArrow' });
    removeHoverOnMobile();
    overlaySearch();
    svg4everybody();
    overlayMenu();
    clearInput();
    checkInput();
    fixedArrow();
    fastResult('#formSearch');
    fastResult('#formSearch2');
    formSearch('#formSearch', '#submitSearch');
    formSearch('#formSearch2', '#submitSearch2');
    formNewsletter('#formNewsletter', '#submitNewsletter');
    showAllResults();
    dynamicPackage();
    overlayShare();
    overlayProducts();
    overlaySectors();
    overlayAbout();
  }

  static leave() {
    setTimeout(() => {
      SCROLLER.destroy();
      PARALLAX.destroy();
      SCROLLFIRE.destroy();
    }, 400);
  }

}


// Classe initialisant les scripts présents dans la page d'accueil
export class Home extends View {

  static initHome() {
    this.init();
    slickBanner();
    slickSectors();
    scrollToBlock({ buttons: '#scrollDown', destination: '#sectorsSection' });
  }

  static leaveHome() {
    this.leave();
    destroySlick('#slickBanner');
    destroySlick('#slickSectors');
  }

}


// Classe initialisant les scripts présents dans la page des produits
export class Products extends View {

  static initProducts() {
    this.init();
    dragAndSlide();
  }

  static leaveProducts() {
    this.leave();
  }

}


// Classe initialisant les scripts présents dans la page d'un produit
export class ProductDetail extends View {

  static initProductDetail() {
    this.init();
    drawers();
    slickImages();
    slickProducts();
    overlayProductSheet();
    overlayQuoteRequest();
    overlayCatalogRequest();
    formProductSheet();
    formQuoteRequest();
    formCatalogRequest();
    textareaHeight();
    manageRubriqueHash();
  }

  static leaveProductDetail() {
    this.leave();
    destroySlick('#slickImages');
    destroySlick('#slickSimilar');
  }

}


// Classe initialisant les scripts présents dans la page de recherche
export class Search extends View {

  static initSearch() {
    this.init();
  }

  static leaveSearch() {
    this.leave();
  }

}


// Classe initialisant les scripts présents dans la page des nouvelles
export class News extends View {

  static initNews() {
    this.init();
    dragAndSlide();
  }

  static leaveNews() {
    this.leave();
  }

}


// Classe initialisant les scripts présents dans la page d'une nouvelle
export class NewsDetail extends View {

  static initNewsDetail() {
    this.init();
    dragAndSlide();
  }

  static leaveNewsDetail() {
    this.leave();
  }

}


// Classe initialisant les scripts présents dans la page de à propos
export class About extends View {

  static initAbout() {
    this.init();
  }

  static leaveAbout() {
    this.leave();
  }

}


// Classe initialisant les scripts présents dans la page d'expertise
export class Expertise extends View {

  static initExpertise() {
    this.init();
  }

  static leaveExpertise() {
    this.leave();
  }

}


// Classe initialisant les scripts présents dans la page des secteurs d'activités
export class Sectors extends View {

  static initSectors() {
    this.init();
  }

  static leaveSectors() {
    this.leave();
  }

}


// Classe initialisant les scripts présents dans la page d'un secteur d'activités
export class SectorDetail extends View {

  static initSectorDetail() {
    this.init();
    slickProductsSector();
  }

  static leaveSectorDetail() {
    this.leave();
    destroySlick('#slickProductsSector');
  }

}


// Classe initialisant les scripts présents dans la page des emplois
export class Jobs extends View {

  static initJobs() {
    this.init();
    formJob();
  }

  static leaveJobs() {
    this.leave();
  }

}


// Classe initialisant les scripts présents dans la page d'un emploi
export class JobDetail extends View {

  static initJobDetail() {
    this.init();
    formJob();
  }

  static leaveJobDetail() {
    this.leave();
  }

}


// Classe initialisant les scripts présents dans la page de contact
export class Contact extends View {

  static initContact() {
    this.init();
    textareaHeight();
    formContact();
    fixInput();
  }

  static leaveContact() {
    this.leave();
  }

}
