/*
 * ---------------------------\\
 * ---- ResizeEvent Class ----\\
 * ---------------------------\\
 */

export class ResizeEvent {

  constructor(instance) {
    if (instance !== undefined) this.instance = instance;

    this.init();
  }


  init() {
    this.boundResize = (e) => this.instance.resize(e);
    window.addEventListener('resize', this.boundResize, true);
  }


  destroy() {
    window.removeEventListener('resize', this.boundResize, true);
  }

}
