/*
 * -----------------------\\
 * ---- Overlay class ----\\
 * -----------------------\\
 */

// Importation ----------------------------------------------------------------------------------------------------
import { ClickEvent } from './CustomEvents/ClickEvent.js';
import { MouseoverEvent } from './CustomEvents/MouseoverEvent.js';
import { TimeoutEvent } from './CustomEvents/TimeoutEvent.js';
import { ResizeEvent } from './CustomEvents/ResizeEvent.js';
import { Debug } from './Debug.js';
import { getElementOffset } from './CustomFunctions/getElementOffset.js';
// -----------------------------------------------------------------------------------------------------------------

export class Overlay {

  constructor(object) {
    this.overlay = object;
    this.isIE = window.navigator.userAgent.indexOf('MSIE ') > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./);
    this.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if (this.overlay.structure === undefined) this.overlay.structure = {};

    if (this.overlay.structure.id === undefined) this.overlay.structure.id = 'overlay' + this.overlay.name.charAt(0).toUpperCase() + this.overlay.name.slice(1);

    if (this.overlay.structure.selector === undefined) this.overlay.structure.selector = 'html';

    if (this.overlay.structure.activeClass === undefined) this.overlay.structure.activeClass = 'show' + this.overlay.structure.id.charAt(0).toUpperCase() + this.overlay.structure.id.slice(1);

    if (this.overlay.structure.genericClass === undefined) this.overlay.structure.genericClass = 'showOverlay';

    if (this.overlay.structure.openingClass === undefined) this.overlay.structure.openingClass = 'opening' + this.overlay.structure.id.charAt(0).toUpperCase() + this.overlay.structure.id.slice(1);

    if (this.overlay.structure.closingClass === undefined) this.overlay.structure.closingClass = 'closing' + this.overlay.structure.id.charAt(0).toUpperCase() + this.overlay.structure.id.slice(1);

    if (this.overlay.structure.buttons === undefined) this.overlay.structure.buttons = {};

    if (this.overlay.event === undefined) this.overlay.event = {};

    if (this.overlay.event.opening === undefined) this.overlay.event.opening = {};

    if (this.overlay.event.closing === undefined) this.overlay.event.closing = {};

    if (this.overlay.event.name === undefined) this.overlay.event.name = 'click';

    if (this.overlay.event.sensitive === undefined) this.overlay.event.sensitive = 'false';

    if (this.overlay.event.speed === undefined) this.overlay.event.speed = 600;

    if (this.overlay.event.delay === undefined) this.overlay.event.delay = 0;

    if (this.overlay.event.cookie === undefined) this.overlay.event.cookie = '';

    if (this.overlay.options === undefined) this.overlay.options = {};

    if (this.overlay.options.goToSelector === undefined) this.overlay.options.goToSelector = '';

    if (this.overlay.options.global === undefined) this.overlay.options.global = 'false';

    if (this.overlay.options.noScroll === undefined) this.overlay.options.noScroll = 'true';

    if (this.overlay.options.closeOnResize === undefined) this.overlay.options.closeOnResize = 'true';

    if (this.overlay.options.customScroll === undefined && !this.isIE) this.overlay.options.customScroll = true;
    else this.overlay.options.customScroll = false;

    this.openEvent = new CustomEvent('openOverlay');
    this.closeEvent = new CustomEvent('closeOverlay');

    this.debug();
  }


  // Check if there is any error before initializing the class
  debug() {
    this.overlay.debug = new Debug(this);
    if (this.overlay.debug.hasError()) console.log(this.overlay.debug.getError());
    else this.init();
  }


  /*
   * Check if the overlay needs to be added to a global variable and add the event on
   * the overlay.
   */
  init() {
    if (this.overlay.global) window['overlay' + this.overlay.name] = this;

    if (this.overlay.event.name == 'click') this.event = new ClickEvent(this);
    else if (this.overlay.event.name == 'mouseover') this.event = new MouseoverEvent(this);
    else if (this.overlay.event.name == 'timeout' && document.querySelectorAll('#' + this.overlay.structure.id).length != 0) this.event = new TimeoutEvent(this);

    if (this.overlay.options.closeOnResize) this.resizeEvent = new ResizeEvent(this);
  }


  /*
   * Open the overlay, it will check if there is any callback, then it will add the active
   * class if it doesn't already exist on the selector. It will check if the back to top and
   * the no scroll option are true. The function will add the opening class and the timeout
   * will remove it. The timeout is for the animation duration from the CSS.
   */
  open(e) {
    const doc = document;
    const html = doc.querySelector('html');
    const selector = doc.querySelector(this.overlay.structure.selector);
    const speed = this.overlay.event.opening.speed !== undefined ? this.overlay.event.opening.speed : this.overlay.event.speed;

    // Cancel the closing
    if (selector.classList.contains(this.overlay.structure.closingClass)) {
      clearTimeout(this.timeout);
      selector.classList.remove(this.overlay.structure.genericClass);
      selector.classList.remove(this.overlay.structure.activeClass);
      selector.classList.remove(this.overlay.structure.closingClass);
    }

    if (typeof this.overlay.event.opening.callback === 'function') {
      if (this.overlay.event.sensitive) {
        this.overlay.event.opening.callback(e);
      } else if (!selector.classList.contains(this.overlay.structure.activeClass)) this.overlay.event.opening.callback(e);
    }

    if (!selector.classList.contains(this.overlay.structure.activeClass)) {
      if (this.overlay.options.goToSelector !== '') html.scrollTop = getElementOffset(this.overlay.options.goToSelector).top;
      if (this.overlay.options.noScroll && !html.classList.contains('noCustomScroll')) this.addNoScroll();
      dispatchEvent(this.openEvent);
      selector.classList.add(this.overlay.structure.openingClass);
      selector.classList.add(this.overlay.structure.genericClass);
      selector.classList.add(this.overlay.structure.activeClass);
      this.timeout = setTimeout(() => {
        selector.classList.remove(this.overlay.structure.openingClass);
      }, speed);
    }
  }


  /*
   * Close the overlay, it will check if there is any callback, then if the active class
   * is there, the function will check for the no scroll option. It will add the closing
   * class and the timeout will remove this class and the active class.
   */
  close(e, keepNoScroll = false) {
    const doc = document;
    const selector = doc.querySelector(this.overlay.structure.selector);
    const speed = this.overlay.event.closing.speed !== undefined ? this.overlay.event.closing.speed : this.overlay.event.speed;

    // Cancel the opening
    if (selector.classList.contains(this.overlay.structure.openingClass)) {
      clearTimeout(this.timeout);
      selector.classList.remove(this.overlay.structure.openingClass);
    }

    if (typeof this.overlay.event.closing.callback === 'function') {
      if (this.overlay.event.sensitive) {
        this.overlay.event.closing.callback(e);
      } else if (!selector.classList.contains(this.overlay.structure.activeClass)) this.overlay.event.closing.callback(e);
    }

    if (selector.classList.contains(this.overlay.structure.activeClass)) {
      if (this.overlay.options.noScroll && !keepNoScroll) {
        this.removeNoScroll();
        dispatchEvent(this.closeEvent);
      }
      selector.classList.add(this.overlay.structure.closingClass);
      this.timeout = setTimeout(() => {
        if (!keepNoScroll) selector.classList.remove(this.overlay.structure.genericClass);
        selector.classList.remove(this.overlay.structure.activeClass);
        selector.classList.remove(this.overlay.structure.closingClass);
      }, speed);
    }
  }


  // Toggle the opening and the closing of the overlay
  toggle(e) {
    if (!document.querySelector(this.overlay.structure.selector).classList.contains(this.overlay.structure.activeClass) || document.querySelector(this.overlay.structure.selector).classList.contains(this.overlay.structure.closingClass)) {
      this.open(e);
    } else {
      this.close(e);
    }
  }


  // Switch the active overlay with another
  switch(e) {
    this.close(e, true);
  }


  // This function will close the overlay if the user resize the browser window.
  resize() {
    const newWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if (newWidth != this.windowWidth) {
      this.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      this.close();
    }
  }


  /*
   * Add the no scroll removing to the user the right to scroll again. There is css
   * associated to the noscroll class. With the custom scroll, it had a gaps to compensate for
   * the absence of the scrollBar
   */
  addNoScroll() {
    const doc = document;
    const html = doc.querySelector('html');

    if (this.overlay.options.customScroll) {
      let i, initialRight, finalRight;
      const fixedElements = doc.querySelectorAll('.fixedOutOfScroll');
      const fixedElementsLength = fixedElements.length;
      const scrollBarWidth = 0;
      html.classList.add('noCustomScroll');
      for (i = 0; i < fixedElementsLength; i++) {
        initialRight = parseInt(getComputedStyle(fixedElements[i]).right, 10);
        finalRight = initialRight + scrollBarWidth + 'px';
        fixedElements[i].style.right = finalRight;
      }
    } else {
      const scrollTop = -html.scrollTop + 'px';
      html.classList.add('noScroll');
      html.style.top = scrollTop;
    }
  }


  // Remove the no scroll giving to the user the right to scroll again.
  removeNoScroll() {
    const doc = document;
    const html = doc.querySelector('html');

    if (this.overlay.options.goToSelector !== '') {
      if (this.overlay.options.customScroll) {
        customScroll();
      } else {
        html.classList.remove('noScroll');
        html.scrollTop = getElementOffset(this.overlay.options.goToSelector).top;
      }
    } else if (this.overlay.options.customScroll) {
      customScroll();
    } else {
      const scrollPosition = parseInt(html.style.top);
      html.classList.remove('noScroll');
      html.scrollTop = -scrollPosition;
    }

    function customScroll() {
      let i;
      const fixedElements = doc.querySelectorAll('.fixedOutOfScroll');
      const fixedElementsLength = fixedElements.length;
      html.classList.remove('noCustomScroll');
      for (i = 0; i < fixedElementsLength; i++) fixedElements[i].style.right = '';
    }
  }


  // Destroy the events of the overlay.
  destroy() {
    this.event.destroy();

    if (this.overlay.options.closeOnResize) this.resizeEvent.destroy();
  }

}
